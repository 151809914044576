(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:DataReconciliationProcessDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('DataReconciliationProcessDialogCtrl', DataReconciliationProcessDialogCtrl);

  function DataReconciliationProcessDialogCtrl($mdDialog) {
    var vm = this;

    vm.processList = [
      {
        title: 'order.dataReconciliation.process.1.title',
        info: 'order.dataReconciliation.process.1.info'
      },
      {
        title: 'order.dataReconciliation.process.2.title',
        info: 'order.dataReconciliation.process.2.info'
      },
      {
        title: 'order.dataReconciliation.process.3.title',
        info: 'order.dataReconciliation.process.3.info'
      },
      {
        title: 'order.dataReconciliation.process.4.title',
        info: 'order.dataReconciliation.process.4.info'
      }
    ];

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };
  }
}());
